let description = document.getElementById('description');
let query = new URLSearchParams(window.location.search);
let npi = query.get('npi');
let hcpName = query.get('hcpName');

let text = `Hi [Colleague’s Name],

I thought you might find the Navigating Current Treatment Guidelines in PAH 
program interesting. You will be receiving a welcome email soon. 
 
Thanks, 
[HCP Name]`;

initializeSharePage();

function initializeSharePage(){
    if(hcpName) 
        text = text.replace('[HCP Name]', hcpName);
    description.value = text;
}

async function onShareClick(e){
    let name = document.getElementById("name").value;
    let errorText = document.getElementById("errors");

    let emailAddress = document.getElementById("emailAddress").value;
    if(!name || !validateEmail(emailAddress)){
        errorText.innerHTML = "Please provide a name and valid email.";
    }else if(name && emailAddress){
        let body = {
            colleagueName: name,
            colleagueEmailAddress: emailAddress,
            npi: npi,
            hcpName: hcpName
        };

        let response = await fetch("/hcp/share", {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-type": "application/json"
            }
        })
        .then((response) => {
            closeForm();
        });
    }
}

function onNameChange(e){
    description.value = text.replace('[Colleague’s Name]', e.value);
}

function closeForm(){
    document.getElementById("headingDescription").innerHTML = "Thank you! Your response was submitted.";
    document.getElementById("shareForm").style.display = 'none';
    document.getElementById("errors").style.display = 'none';
}

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };